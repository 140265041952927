import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Typography,
  message,
} from "antd";
import { useRef, useState } from "react";
import moment from "moment";
import { string_to_double } from "../../../../../../constants";
import IntlMessages from "util/IntlMessages";
import { addVehicle } from "./network_requests";

const success = () => {
  message.success("The vehicle has been added.");
};

const error = () => {
  message.error("Error happened, please try again.");
};

export default function AddVehicle(props) {
  const { open, setOpen, parent } = props;

  const [nfeld1, setnfeld1] = useState(null);
  const ref = useRef();
  const { Text } = Typography;

  const commatoPointParser = (value) => {
    // Convert commas to dots and parse as float
    return parseFloat(value.replace(/,/g, "."));
  };

  return (
    <Modal
      title={"Fahrzeug anlegen"}
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        // setOpen(!open);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(!open);
      }}
      style={{
        minWidth: "58vw",
      }}
    >
      <Form
        name="add artist"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={
          {
            // remember: true,
          }
        }
        style={{
          paddingLeft: "20px",
        }}
        onFinish={async (values) => {
          const response = await addVehicle(
            {
              ...values,
              nfeld1: nfeld1 ? string_to_double(nfeld1) : 0,
              nfeld2: values?.nfeld2 ? commatoPointParser(values.nfeld2) : 0,
            },
            parent
          );
          if (response?.success) {
            success();
          } else {
            error();
          }
          props.setUpdate(!props.update);
          setnfeld1(moment(moment(), "DD.MM.YYYY"));
          setOpen(!open);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={8}>
            <Form.Item
              name="cfeld1"
              label="Name/Type"
              style={{ marginBottom: 0 }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col xs={1} /> */}
          <Col xs={8}>
            <Form.Item
              name="cfeld2"
              label="Kennzeichen"
              style={{ marginBottom: 0, marginLeft: "10px" }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col xs={1} /> */}
          <Col xs={8}>
            <Form.Item
              name="nfeld2"
              label="Preis je Stunde (€)"
              style={{ marginBottom: 0, marginLeft: "10px" }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={8}>
            <Form.Item
              name="cfeld3"
              label="Zustand"
              style={{ marginBottom: 0 }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col xs={1} /> */}
          <Col xs={8}>
            <Form.Item
              name="cfeld4"
              label="Dienststelle"
              style={{ marginBottom: 0, marginLeft: "10px" }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col xs={1} /> */}
          <Col xs={8}>
            <Form.Item
              name="cfeld5"
              label="Inventar No"
              style={{ marginBottom: 0, marginLeft: "10px" }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={8}>
            <Form.Item label="Erstzulassung">
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld1(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={24}>
            <Form.Item name="cfeld6" label="Kommentar">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
