import { Input, Form, Typography, Button, Row, Col, message } from "antd";
import { useRef, useState } from "react";
import { editCheckPoint } from "./network_requests";
import { useHistory } from "react-router";
import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import Documents from "./documents";
import Scan from "./scan";

export default function EditCheckPoint(props) {
  const { checkPoint, setCheckPoint } = props;

  const onWrite = async (tag) => {
    try {
      if ("NDEFReader" in window) {
        const ndef = new window.NDEFReader();

        await ndef.write({
          records: [
            {
              recordType: "text",
              data: tag,
            },
          ],
        });

        await editCheckPoint({
          ...checkPoint,
          nfeld5: 1,
        });

        message.success("Value Saved");
      } else {
        message.error("NFC is not supported on this device.");
      }
    } catch (error) {
      message.error(`Error: ${error.message}`);
      console.error(error);
    }
  };

  const [photoActive, setPhotoActive] = useState(false);
  const [scanActive, setScanActive] = useState(false);
  const ref = useRef();
  const history = useHistory();

  return (
    <>
      {photoActive ? (
        <Documents templateKey={checkPoint?.stkey} setActive={setPhotoActive} />
      ) : scanActive ? (
        <Scan
          checkPoint={checkPoint}
          setActive={setScanActive}
          setLoad={props?.setLoad}
          update={props?.update}
          setUpdate={props?.setUpdate}
        />
      ) : (
        <div
          style={{
            backgroundColor: "#f3f7ff",
            height: "85vh",
          }}
        >
          <>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  color: "#038fde",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCheckPoint(null);
                }}
              >
                <ArrowLeftOutlined
                  style={{ fontSize: "22px", fontWeight: "bold" }}
                />
              </Typography>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 450,
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                Edit CheckPoint
              </Typography>
            </div>
          </>
          <Form
            name="edit CheckPoint"
            ref={ref}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              padding: "10px 30px",
            }}
            initialValues={{
              ...checkPoint,
            }}
            onFinish={async (values) => {
              setCheckPoint(null);
              props.setLoad(true);
              const response = await editCheckPoint({
                ...checkPoint,
                ...values,
              });

              if (response.status === 401) {
                localStorage.removeItem("user_id");
                history.go(0);
              }

              props.setUpdate(!props.update);
            }}
            //   onFinishFailed={onFinishFailed}
            //   autoComplete="off"
          >
            <Form.Item
              label={"Title"}
              name="cfeld1"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Kommentar"}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <TextArea rows={6} />
            </Form.Item>

            <Row
              style={{
                marginTop: "16px",
                position: "absolute",
                bottom: "150px",
                display: "flex",
                width: "100%",
                padding: "0px 15px",
              }}
            >
              <Col
                xs={12}
                style={{
                  padding: 0,
                }}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    style={{
                      width: "150px",
                    }}
                    onClick={async () => {
                      props.setLoad(true);
                      const response = await editCheckPoint({
                        ...checkPoint,
                        active: 0,
                      });

                      if (response.status === 401) {
                        localStorage.removeItem("user_id");
                        history.go(0);
                      }

                      props.setUpdate(!props.update);
                    }}
                  >
                    Remove
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            <Row
              style={{
                marginTop: "16px",
                position: "absolute",
                bottom: "100px",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                padding: "0px 15px",
              }}
            >
              <Col
                xs={12}
                style={{
                  padding: 0,
                }}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    style={{
                      width: "150px",
                    }}
                    onClick={() => {
                      setPhotoActive(!photoActive);
                    }}
                  >
                    Photos
                  </Button>
                </Form.Item>
              </Col>
              <Col
                xs={12}
                style={{
                  padding: 0,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "150px",
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>

            <Row
              style={{
                marginTop: "16px",
                position: "absolute",
                bottom: "50px",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                padding: "0px 15px",
              }}
            >
              <Col
                xs={12}
                style={{
                  padding: 0,
                }}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    style={{
                      width: "150px",
                    }}
                    onClick={() => {
                      onWrite(checkPoint?.cfeld19);
                    }}
                  >
                    Write NFC
                  </Button>
                </Form.Item>
              </Col>
              <Col
                xs={12}
                style={{
                  padding: 0,
                }}
              >
                <Button
                  type="primary"
                  style={{
                    width: "150px",
                  }}
                  onClick={() => {
                    setScanActive(!scanActive);
                  }}
                >
                  Read NFC
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}{" "}
    </>
  );
}
