import { Modal, Input, Form, Row, Col } from "antd";
import { useRef } from "react";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { addSubarea, editSubarea } from "./network_requests";
import "./style.css";

export default function AddSubarea(props) {
  const { open, setOpen } = props;

  const [form] = Form.useForm();

  const history = useHistory();
  const ref = useRef();

  const commatoPointParser = (value) => {
    // Convert commas to dots and parse as float
    return parseFloat(value.replace(/,/g, "."));
  };

  const scrollableMenuStyle = {
    maxHeight: "200px",
    overflow: "auto",
  };

  return (
    <Modal
      title="Edit Subarea"
      width="660"
      visible={Boolean(open)}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        ref?.current?.submit();
        setOpen(!open);
      }}
      onCancel={() => {
        setOpen(!open);
      }}
      style={{
        maxWidth: "920px",
      }}
    >
      <Form
        name="edit Subarea"
        ref={ref}
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...open,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await editSubarea({
            ...open,
            ...values,
          });
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item label={"Name"} name="cfeld2" style={{ marginBottom: 0 }}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={"Community"}
              name="cfeld1"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={"Color"}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={"Size (ha)"}
              name="nfeld1"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label={"Comment"} name="cfeld4" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
