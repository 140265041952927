import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Typography,
  message,
} from "antd";
import {
  double_to_string,
  string_to_double,
} from "../../../../../../constants";
import { useEffect, useRef, useState } from "react";
import moment from "moment";

//   import { editArtist } from "../networkrequests";
import IntlMessages from "util/IntlMessages";
import { editStaff } from "./network_requests";

const success = () => {
  message.success("Speichern erfolgreich!");
};

const error = () => {
  message.error("Error happened, please try again.");
};

export default function EditStaff(props) {
  const { staffItem, setEstate } = props;
  const open = Boolean(staffItem);
  console.log(open);

  const [nfeld1, setnfeld1] = useState(
    staffItem?.nfeld1
      ? moment(double_to_string(staffItem?.nfeld1), "DD.MM.YYYY")
      : null
  );

  useEffect(() => {
    setnfeld1(
      staffItem?.nfeld1
        ? moment(double_to_string(staffItem?.nfeld1), "DD.MM.YYYY")
        : null
    );
  }, [staffItem]);

  const ref = useRef();
  const { Text } = Typography;

  const commatoPointParser = (value) => {
    // Convert commas to dots and parse as float
    return parseFloat(value.replace(/,/g, "."));
  };

  return (
    <Modal
      // title={"Mitarbeiterdaten bearbeiten"}
      title={"Staff data edit"}
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        // setOpen(!open);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setEstate(null);
      }}
      style={{
        minWidth: "58vw",
      }}
    >
      <Form
        name="edit staff"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...staffItem,
        }}
        style={{
          paddingLeft: "20px",
        }}
        onFinish={async (values) => {
          console.log(values);
          props.setLoad(true);
          const response = await editStaff({
            ...staffItem,
            ...values,
            nfeld1: nfeld1 ? string_to_double(nfeld1) : 0,
            nfeld2: values?.nfeld2 ? commatoPointParser(values.nfeld2) : 0,
          });
          if (response?.success) {
            success();
          } else {
            error();
          }
          props.setUpdate(!props.update);
          setEstate(null);
        }}
        autoComplete="off"
      >
        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={8}>
            <Form.Item
              name="cfeld1"
              label="Name"
              style={{ marginBottom: 0 }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col xs={1} /> */}
          <Col xs={8}>
            <Form.Item
              name="cfeld2"
              label="ID"
              style={{ marginBottom: 0, marginLeft: "10px" }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col xs={1} /> */}
          <Col xs={8}>
            <Form.Item
              name="nfeld2"
              label="Preis/h (€)"
              style={{ marginBottom: 0, marginLeft: "10px" }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={8}>
            <Form.Item label="Mitarbeiter seit">
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld1(d);
                }}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={1} /> */}
          <Col xs={8}>
            <Form.Item
              name="cfeld4"
              label="Dienststelle"
              style={{ marginBottom: 0, marginLeft: "10px" }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col xs={1} /> */}
          <Col xs={8}>
            <Form.Item
              name="cfeld5"
              label="Telefon"
              style={{ marginBottom: 0, marginLeft: "10px" }}
              // rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={8}>
            <Form.Item name="cfeld6" label="Drivers licenses">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={24}>
            <Form.Item name="cfeld7" label="Kommentar">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
