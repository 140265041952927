import {
  Row,
  Col,
  Typography,
  Button,
  Pagination,
  Spin,
  Menu,
  Dropdown,
  Input,
  Popconfirm,
  Tooltip,
  Checkbox,
} from "antd";
import { useEffect, useState } from "react";
import {
  getProperties,
  editProperty,
  getCoordinates,
  getFields,
  excelForProperties,
  getAreas,
  getAreaCoordinates,
  getSubAreas,
  getSubAreaCoordinates,
} from "./network_requests";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  MapConsumer,
  Polygon,
  CircleMarker,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { polygon } from "leaflet";
import tree_icon from "../../../../assets/Icons_waste/tree.webp";
import { useHistory } from "react-router";
import AddProperty from "./AddProperty";
import EditProperty from "./EditProperty";
import { locationIcon } from "../../../../constants";
import DragComponent from "../../../extraComponents/MoreActions/MapMoreActions";
import PropertyMoreActions from "./MoreActions";
import IntlMessages from "util/IntlMessages";
import Coordinate from "./coordinates";
import { FileExcelOutlined } from "@ant-design/icons";
const { Text } = Typography;
const { Search } = Input;

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

const Properties = () => {
  const [properties, setProperties] = useState([]);
  const [count, setCount] = useState(null);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [PropertiesMarkers, setPropertiesMarkers] = useState([]);
  const [center, setCenter] = useState([0, 0]);
  const [open, setOpen] = useState(false);
  const [coordinateOpen, setCoordinateOpen] = useState(false);
  const [edit, setEdit] = useState(null);
  const [update, setUpdate] = useState(false);
  const [addLoc, setAddLoc] = useState(true);
  const [latlng, setLatlng] = useState(null);
  const [map, setMap] = useState(null);
  const [position, setPosition] = useState(null);
  const [searchType, setSearchType] = useState(null);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [property, setProperty] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const history = useHistory();
  const [fields, setFields] = useState({});
  const [area, setArea] = useState(null);
  const [subArea, setSubArea] = useState(null);
  const [areaCoordinates, setAreaCoordinates] = useState([]);
  const [subAreaCoordinates, setsubAreaCoordinates] = useState([]);
  const [areaColor, setAreaColor] = useState(null);
  const [subAreaColor, setSubAreaColor] = useState("#F6FF8D");
  const [drawArea, setDrawArea] = useState(false);
  const [drawsubArea, setDrawsubArea] = useState(false);

  const menu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setSearchType(null);
          setSearchText("");
          setSearch("");
        }}
      >
        <IntlMessages id="search.Reset_Search" />
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          setSearchType("Tarla");
          setSearchText("");
        }}
      >
        Search by Tarla
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          setSearchType("Tarla;Parcella");
          setSearchText("");
        }}
      >
        Search by Tarla;Parcella
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => {
          setSearchType("Community");
          setSearchText("");
        }}
      >
        Search by Community
      </Menu.Item>
      <Menu.Item
        key="5"
        onClick={() => {
          setSearchType("Contract no");
          setSearchText("");
        }}
      >
        Search by Contract no
      </Menu.Item>
      <Menu.Item
        key="6"
        onClick={() => {
          setSearchType("Contract type");
          setSearchText("");
        }}
      >
        Search by Contract type
      </Menu.Item>
      <Menu.Item
        key="7"
        onClick={() => {
          setSearchType("owner name");
          setSearchText("");
        }}
      >
        Search by Previous owner name
      </Menu.Item>
    </Menu>
  );

  const icon = L.icon({
    iconUrl: tree_icon,
    iconSize: [30, 30], // size of the icon
    shadowSize: [50, 64], // size of the shadow
  });

  useEffect(async () => {
    setLoad(searchType !== "center" ? true : load);
    setPropertiesMarkers([]);
    const Properties_ = await getProperties(
      (page - 1) * pageSize,
      pageSize,
      searchType === "Tarla"
        ? [{ fieldNo: "cfeld2", val: search, opVal: "=" }]
        : searchType === "Tarla;Parcella"
        ? [{ fieldNo: "cfeld1", val: search, opVal: "=" }]
        : searchType === "Community"
        ? [{ fieldNo: "cfeld3", val: search, opVal: "=" }]
        : searchType === "Contract no"
        ? [{ fieldNo: "cfeld4", val: search, opVal: "=" }]
        : searchType === "Contract type"
        ? [{ fieldNo: "cfeld5", val: search, opVal: "=" }]
        : searchType === "owner name"
        ? [{ fieldNo: "cfeld6", val: search, opVal: "=" }]
        : []
    );

    if (Properties_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    const areas_ = await getAreas();
    if (areas_?.list?.length > 0) {
      setArea(areas_?.list[0]);
    }

    const subareas_ = await getSubAreas();
    if (subareas_?.list?.length > 0) {
      setSubArea(subareas_?.list);
    }

    setProperties(Properties_?.list);
    setCount(Properties_?.count);
    const community = await getFields("G15C01D00F03");
    const owner = await getFields("G15C01D00F06");
    const contracttype = await getFields("G15C01D00F05");

    setFields({
      community,
      owner,
      contracttype,
    });

    setLoad(false);
  }, [update, page, pageSize, search]);

  useEffect(async () => {
    if (drawArea && area) {
      const areaCoordinates_ = await getAreaCoordinates(area?.stkey);
      if (areaCoordinates_?.list?.length > 0) {
        setAreaCoordinates(
          areaCoordinates_?.list?.map((polygon) => {
            return [polygon?.nfeld2, polygon?.nfeld1];
          })
        );
        setAreaColor(areaCoordinates_?.list[0]?.cfeld2);
        setCenter([
          areaCoordinates_?.list[0]?.nfeld2,
          areaCoordinates_?.list[0]?.nfeld1,
        ]);
      }
    }
    if (!drawArea) {
      setAreaCoordinates([]);
    }
  }, [drawArea]);

  useEffect(async () => {
    if (drawsubArea && subArea) {
      setsubAreaCoordinates([]);

      let polygon_ = [];

      subArea?.map(async (subArea_, index) => {
        const response_ = await getSubAreaCoordinates(subArea_?.stkey);
        if (response_?.status === 401) {
          localStorage.removeItem("user_id");
          history.go(0);
        }
        if (response_?.count > 0) {
          polygon_ = [
            ...polygon_,
            {
              color: subArea_?.cfeld3 ?? "#F6FF8D",
              text: subArea_?.cfeld2,
              coordinates: [
                ...response_?.list?.map((polygon) => {
                  return [polygon?.nfeld2, polygon?.nfeld1];
                }),
              ],
            },
          ];
          setCenter([response_?.list[0]?.nfeld2, response_?.list[0]?.nfeld1]);

          setsubAreaCoordinates(polygon_);
          //  }
        }
      });
    }
    if (!drawArea) {
      setsubAreaCoordinates([]);
    }
  }, [drawsubArea]);

  useEffect(() => {
    if (map)
      map?.locate()?.on("locationfound", function (e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng);
      });
  }, [map]);

  useEffect(() => {
    if (center) {
      if (map) {
        map?.flyTo(center);
      }
    }
  }, [center]);

  useEffect(async () => {
    if (property) {
      const response_ = await getCoordinates(0, 20, property?.stkey);
      console.log(property);
      if (response_?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      if (response_?.count > 0) {
        setPolygons([
          ...polygons,

          {
            text: property.cfeld1,
            color: property.cfeld20,
            coordinates: [
              ...response_?.list?.map((polygon) => {
                return [polygon?.nfeld2, polygon?.nfeld1];
              }),
            ],
          },
        ]);
        setCenter([response_?.list[0]?.nfeld2, response_?.list[0]?.nfeld1]);
        setPropertiesMarkers([
          ...PropertiesMarkers,
          { ...response_?.list[0], property: property?.cfeld1 },
        ]);
      }
    }
  }, [property]);

  console.log(polygons);

  return (
    <div>
      {addLoc ? (
        <AddProperty
          open={open}
          setOpen={setOpen}
          setLoad={setLoad}
          update={update}
          setUpdate={setUpdate}
          latlng={latlng}
          fields={fields}
        />
      ) : null}
      {Boolean(edit) ? (
        <EditProperty
          open={edit}
          setOpen={setEdit}
          setLoad={setLoad}
          update={update}
          setUpdate={setUpdate}
          fields={fields}
        />
      ) : null}
      <Coordinate open={coordinateOpen} setOpen={setCoordinateOpen} />
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}

      {!load ? (
        <Row>
          <Col
            xs={12}
            sm={12}
            xl={8}
            style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
          >
            {searchType ? (
              <div>
                {searchType === "center" ? (
                  <Row
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #eaeaea",
                      fontWeight: 400,
                      padding: "10px 10px",
                      margin: "20px 0 0 0",
                      color: "#808080",
                    }}
                  >
                    <Col
                      xs={12}
                      sm={12}
                      xl={18}
                      style={{
                        padding: 0,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <SearchOutlined
                        style={{ color: "#808080", paddingRight: "10px" }}
                      />
                      <Text>Search around {searchType}</Text>
                    </Col>
                  </Row>
                ) : (
                  <Search
                    placeholder={`Search for ${searchType}`}
                    onSearch={(value) => {
                      setSearch(value);
                    }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    value={searchText}
                    style={{ margin: "5px 0 0 0", padding: 0 }}
                    enterButton
                  />
                )}
              </div>
            ) : null}
            <Row
              style={{
                backgroundColor: "#FAFAFA",
                borderBottom: "1px solid #eaeaea",
                fontWeight: 500,
                padding: "10px 30px",
                margin: searchType ? "5px 0 0 0" : "20px 0 0 0",
              }}
            >
              <Col xs={10} sm={12} xl={18} style={{ padding: 0 }}>
                <Text>
                  <IntlMessages id="Properties" />
                </Text>
              </Col>
              <Col xs={2}>
                <Popconfirm
                  title="Create Properties Excel?"
                  onConfirm={async () => {
                    const response = await excelForProperties({
                      stkey: "none",
                    });
                    if (response?.file) {
                      downloadToLocal(response?.file, response?.filename);
                      //  downloadPDF(response?.file, response?.filename);
                    }
                  }}
                  okText="Ja"
                  cancelText="Nein"
                >
                  <Tooltip title="Create Excel from properties.">
                    <FileExcelOutlined style={{ cursor: "pointer" }} />
                  </Tooltip>
                </Popconfirm>
              </Col>
            </Row>
            {properties?.map((property) => {
              return (
                <Row
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #eaeaea",
                    padding: "10px 30px",
                    margin: "0",
                  }}
                >
                  <Col
                    xs={12}
                    sm={12}
                    xl={18}
                    style={{
                      padding: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setProperty({ ...property });

                      // setCenter([property?.nfeld2, property?.nfeld1]);
                      //  setEditEstates(estate);
                    }}
                  >
                    <Text
                      style={{
                        color: "#038fde",
                      }}
                    >
                      {property?.cfeld2};{property?.cfeld1}
                    </Text>
                  </Col>

                  <Col>
                    <PropertyMoreActions
                      state={property}
                      setEdit={setEdit}
                      edit={editProperty}
                      coordinateOpen={coordinateOpen}
                      setCoordinateOpen={setCoordinateOpen}
                      update={update}
                      setUpdate={setUpdate}
                    />
                    <DownOutlined
                      size="small"
                      style={{
                        color: "#038fde",
                        paddingLeft: "10px",
                        fontSize: "10px",
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
            <Row
              style={{
                backgroundColor: "#FAFAFA",
                borderBottom: "1px solid #eaeaea",
                fontWeight: 500,
                padding: "10px",
                margin: "0",
              }}
            >
              <Col span={12}>
                <Button
                  size="small"
                  type="text"
                  style={{
                    margin: 0,
                    color: "#038fde",
                  }}
                  onClick={async () => {
                    setPolygons([]);
                    setPropertiesMarkers([]);

                    let polygon_ = [];
                    let markers_ = [];

                    properties?.map(async (property_, index) => {
                      const response_ = await getCoordinates(
                        0,
                        10,
                        property_?.stkey
                      );
                      if (response_?.status === 401) {
                        localStorage.removeItem("user_id");
                        history.go(0);
                      }
                      if (response_?.count > 0) {
                        polygon_ = [
                          ...polygon_,
                          {
                            text: property_?.cfeld2,
                            coordinates: [
                              ...response_?.list?.map((polygon) => {
                                return [polygon?.nfeld2, polygon?.nfeld1];
                              }),
                            ],
                          },
                        ];
                        setCenter([
                          response_?.list[0]?.nfeld2,
                          response_?.list[0]?.nfeld1,
                        ]);

                        markers_ = [
                          ...markers_,
                          {
                            ...response_?.list[0],
                            property: property_?.cfeld1,
                          },
                        ];
                        // if (index === properties?.length - 1) {
                        setPropertiesMarkers(markers_);
                        setPolygons(polygon_);
                        //  }
                      }
                    });
                  }}
                >
                  show on map
                  {/* <IntlMessages id="estate.add" /> */}
                </Button>
              </Col>
              <Col
                span={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Dropdown overlay={menu} trigger={["click"]}>
                  <Button
                    size="small"
                    type="text"
                    style={{
                      margin: 0,
                      color: "#038fde",
                    }}
                    onClick={() => {
                      //setOpen(!open);
                    }}
                  >
                    Search options
                    {/* <IntlMessages id="estate.add" /> */}
                  </Button>
                </Dropdown>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "#fff",
                margin: "0",
                padding: "10px 0",
              }}
            >
              <Col xs={8}>
                <Button
                  type="primary"
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  Add Property
                </Button>
              </Col>
              <Col
                xs={16}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Pagination
                  defaultCurrent={1}
                  total={count}
                  current={page}
                  pageSize={pageSize}
                  pageSizeOptions={[5, 10, 15]}
                  style={{
                    margin: 0,
                  }}
                  onChange={(newpage, pageSize_) => {
                    setPage(newpage);
                    setPageSize(pageSize_);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={1} />
          <Col
            xs={11}
            sm={11}
            xl={15}
            style={{
              padding: "0 25px",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
            }}
          >
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingTop: "20px",
                marginRight: "10px",
              }}
            >
              <Checkbox
                onChange={(e) => {
                  if (!e.target.checked) {
                    setAreaCoordinates([]);
                  }
                  setDrawArea(e.target.checked);
                }}
              >
                Show Area
              </Checkbox>
              <Checkbox
                onChange={(e) => {
                  if (!e.target.checked) {
                    setsubAreaCoordinates([]);
                  }
                  setDrawsubArea(e.target.checked);
                }}
              >
                Show Sub Areas
              </Checkbox>
              <Button
                type="link"
                onClick={() => {
                  setPropertiesMarkers([]);
                  setPolygons([]);
                  setAreaCoordinates([]);
                  setsubAreaCoordinates([]);
                }}
                style={{ padding: 0, margin: 0 }}
              >
                Clear Map
              </Button>
            </Row>
            {center ? (
              <MapContainer
                center={center}
                zoom={30}
                scrollWheelZoom={false}
                whenCreated={setMap}
                style={{
                  height: "95vh",
                }}
                tap={false}
              >
                <TileLayer
                  // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                  subdomains={["mt1", "mt2", "mt3"]}
                />
                <MapConsumer>
                  {(map) => {
                    map.on("click", function (e) {
                      const { lat, lng } = e.latlng;
                      setLatlng({
                        lat: lat,
                        lng: lng,
                      });
                      // setOpen(!open);
                    });
                    return null;
                  }}
                </MapConsumer>
                {position ? (
                  <Marker position={position} icon={locationIcon}>
                    <Popup>
                      You are here. <br />
                    </Popup>
                  </Marker>
                ) : null}
                {polygons?.map((polygon_) => {
                  return (
                    <PolygonWithText
                      positions={[...polygon_?.coordinates]}
                      text={polygon_?.text}
                      color={polygon_?.color ?? "blue"}
                    />
                  );
                })}

                {areaColor ? (
                  <Polygon positions={[...areaCoordinates]} color={areaColor} />
                ) : // <Polygon positions={[...areaCoordinates]} color={areaColor} />
                null}
                {subAreaCoordinates?.map((item) => {
                  return (
                    <PolygonWithText
                      positions={[...item?.coordinates]}
                      color={item?.color}
                      text={item?.text}
                    ></PolygonWithText>
                  );
                })}
                {/* {PropertiesMarkers?.map((mark) => {
                  return (
                    <CircleMarker center={[mark.nfeld2, mark.nfeld1]}>
                      <Popup>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setEdit(mark);
                          }}
                        >
                          {mark?.property}
                        </div>
                      </Popup>
                    </CircleMarker>
                  );
                })} */}
              </MapContainer>
            ) : null}
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default Properties;

const PolygonWithText = (props) => {
  const center = L.polygon(props?.positions).getBounds().getCenter();
  const text = L.divIcon({ html: "<span></span>" });
  const myCustomColour = "#fff";

  const markerHtmlStyles = `
  background-color: ${myCustomColour};
  border-radius: 40px;
  color: blue;
  padding: 10px;
  width:100%;
  
  `;

  const icon = L.divIcon({
    className: "my-custom-pin",

    html: `<span style="${markerHtmlStyles}">${props.text?.replace(
      " ",
      ""
    )}</span>`,
  });

  return (
    <Polygon positions={props.positions} color={props.color}>
      <Marker position={center} icon={icon} />
    </Polygon>
  );
};
